
import axios from 'axios';
export default {
    
    async createItemSizes(params)  {
        return await axios.post(`item_sizes/create` , params)
    },
    async createItemSizesList(params)  {
        return await axios.post(`item_sizes/create/list` , params)
    },
    async updateItemSizesColumn(column , value , data)  {
        return await axios.put(`item_sizes/update_list?${column}=${value}` , data)
    },
    async deleteItemSizesList(list)  {
        return await axios.delete(`item_sizes/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportItemSizes(column , value)  {
        return await axios.get(`item_sizes/report?${column}=${value}`)
    },
    async getAllItemSizes()  {
        return await axios.get(`item_sizes/all`)
    },
    async getOneItemSizes(item_size_id)  {
        return await axios.get(`item_sizes/all/${item_size_id}`)
    },
    async getItemSizesByColumn(column , value)  {
        return await axios.get(`item_sizes/filter?column=${column}&value=${value}`)
    },
    async deleteItemSizes(item_size_id)  {
        return await axios.delete(`item_sizes/delete/${item_size_id}`)
    },
    async updateItemSizes(item_size_id , params)  {
        return await axios.put(`item_sizes/update/${item_size_id}` , params)
    }
}